import React, { FC } from 'react'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plus-circle.svg'
import cn from './selected-filters.module.scss'
import { useTranslation } from 'react-i18next'

interface IFilters {
    filter: Record<string, { label: string; values: { label: string; value: string }[] }>
    onDelete: (filterIndex: number, valueKey: string, value: string) => void
    index: number
    removeFilter: (filterIndex: number) => void
    type: 'add' | 'shrink'
}

const Index: FC<IFilters> = ({ filter, onDelete, index, removeFilter, type }) => {
    const { t } = useTranslation()
    return (
        <div className={cn['root']}>
            <Flex className={cn['header']} align="center" content="space-between">
                <Flex align={'center'}>
                    <PlusIcon style={{ marginRight: '6px' }} />
                    <Text fSize={'14px'} color={'gray-new-1'} semibold>
                        {type === 'add' ? t('target_audience.expanded') : t('target_audience.excluded')}
                    </Text>
                </Flex>
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => removeFilter(index)} />
            </Flex>
            {Object.entries(filter).map(([key, f]) => (
                <Flex key={key} className={cn['filters-item']} column>
                    <Text margin={'0 0 10px 0'} fSize={'12px'} color={'gray-new-4'}>
                        {t(f?.label)}
                    </Text>
                    <Flex className={cn['item-wrapper']}>
                        {f?.values?.map((item) => (
                            <div
                                key={item.value}
                                className={cn['item']}
                                onClick={() => onDelete(index, key, item.value)}
                            >
                                {item.label} ✕
                            </div>
                        ))}
                    </Flex>
                </Flex>
            ))}
        </div>
    )
}
export default Index
