import React from 'react'
import styles from './sidebar.module.scss'
import Text from '@/components/Text'
import { Button, Flex } from '@/_yc'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UserIcon } from '@/pages/TargetAudience/icons/users.svg'
import { ReactComponent as BilineIcon } from '@/pages/TargetAudience/icons/biline.svg'
import { ReactComponent as YolocoIcon } from '@/pages/TargetAudience/icons/yoloco.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setBasedOnData } from '@/_redux/_reducers/target_audience.reducer'

const options = [
    {
        key: 'Yoloco',
        title: 'target_audience.yoloco_data',
        description: 'Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов.',
        icon: <YolocoIcon />,
    },
    {
        key: 'Beeline',
        title: 'target_audience.beeline_audience_data',
        description: 'Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов.',
        icon: <BilineIcon />,
    },
    {
        key: 'Users',
        title: 'target_audience.user_data',
        description: 'Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов.',
        icon: <UserIcon />,
    },
]

const Sidebar: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleSelect = (key: string) => {
        dispatch(setBasedOnData(key))
    }

    return (
        <div className={styles.sidebar}>
            {options.map((option, index) => (
                <Flex key={index} className={styles.optionWrapper}>
                    <div className={styles.icon}>{option.icon}</div>
                    <Flex className={styles.option}>
                        <div className={styles.details}>
                            <Text component="h3" fSize={'18px'} semibold color={'gray-new-1'}>
                                {t(option.title)}
                            </Text>
                            <Text fSize={'12px'} color={'gray-new-4'}>
                                {option.description}
                            </Text>
                        </div>
                        <Link to="/target-audience/step-two">
                            <Button className={styles.selectButton} onClick={() => handleSelect(option.key)}>
                                Выбрать
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
            ))}
        </div>
    )
}

export default Sidebar
