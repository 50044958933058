import React, { FC, useCallback, useEffect, useState } from 'react'
import DynamicMenu from '../DynamicMenu'
import MultiSelect from '@/pages/TargetAudience/components/MultiSelect'
import cn from './filter.module.scss'
import Button from '@/components/Button'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { ReactComponent as ShrinkIcon } from '../../../../icons/shrink.svg'
import { ReactComponent as AddIcon } from '../../../../icons/add.svg'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface Option {
    label: string
    value: string
}

interface FilterProps {
    onSubmit: (selectedFilters: Record<string, { label: string; values: any[] }>) => void
    onSubmitDraft: (selectedFilters: Record<string, { label: string; values: any[] }>) => void
    initialOptions: Option[]
    selectTypesOptions: Record<string, Option[]>
    type: 'add' | 'shrink'
    title: string
    description: string
}

const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: '#5D697D',
        padding: '16px',
        fontSize: '10px',
        fontWeight: 500,
        textAlign: 'center',
    },
}))

const Filter: FC<FilterProps> = ({
    onSubmit,
    onSubmitDraft,
    initialOptions,
    selectTypesOptions,
    type,
    title,
    description,
}) => {
    const { t } = useTranslation()
    const [availableOptions, setAvailableOptions] = useState<Option[]>(initialOptions)
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([])
    const [filters, setFilters] = useState<Record<string, { label: string; values: any[] }>>({})
    const classes = useStyles()

    const handleSelectOption = (option: Option) => {
        setSelectedOptions((prevSelected) => [...prevSelected, option])

        setAvailableOptions((prevAvailable) => prevAvailable.filter((opt) => opt.value !== option.value))
    }

    const handleMultiSelectChange = useCallback(
        (optionValue: string, optionLabel: string) => (selectedValues: any[]) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [optionValue]: { label: optionLabel, values: selectedValues },
            }))
        },
        []
    )

    const handleSubmit = () => {
        onSubmit(filters)
        setSelectedOptions([])
        setFilters({})
        setAvailableOptions(initialOptions)
    }

    useEffect(() => {
        onSubmitDraft(filters)
    }, [filters, onSubmitDraft])

    return (
        <Flex className={cn['container']}>
            <Flex content="space-between" align="start" margin="0 0 12px 0">
                {type === 'add' ? <AddIcon /> : <ShrinkIcon />}
                <Text className={cn['title']} fSize={12} color="gray-new-4">
                    {t('target_audience.more_info')}{' '}
                    <Tooltip
                        title={
                            type === 'add'
                                ? (t('target_audience.expand_search_description') as string)
                                : (t('target_audience.narrow_search_description') as string)
                        }
                        arrow
                        placement="top"
                        classes={{ tooltip: classes.customTooltip }}
                    >
                        <span>i</span>
                    </Tooltip>
                </Text>
            </Flex>
            <Flex className={cn['description']} column margin="0 0 20px 0">
                <Text component="h3" margin="0 0 12px 0" fSize={18} color="gray-new-1">
                    {title}
                </Text>
                <Text fSize={14} color="gray-new-4">
                    {description}
                </Text>
            </Flex>
            <Flex column>
                {selectedOptions.map((option) => (
                    <Flex column key={option.value} margin="0 0 20px 0">
                        <Text fSize={12} color="gray-new-4">
                            {t(option.label)}
                        </Text>
                        <MultiSelect
                            options={selectTypesOptions[option.value]}
                            placeholder={t(option.label)}
                            onChange={handleMultiSelectChange(option.value, option.label)}
                        />
                    </Flex>
                ))}
            </Flex>
            <Flex column>
                {availableOptions.length > 0 && (
                    <Flex align="center" margin="0 0 24px 0">
                        <DynamicMenu options={availableOptions} onSelect={handleSelectOption} type={type} />
                        <Text margin="0 0 0 12px" fSize={14} color="violet-1">
                            {type === 'add'
                                ? t('target_audience.select_parameter')
                                : t('target_audience.exclude_parameter')}
                        </Text>
                    </Flex>
                )}
                <Button className={cn['btn']} disabled={selectedOptions.length === 0} onClick={handleSubmit}>
                    {t('target_audience.add')}
                </Button>
            </Flex>
        </Flex>
    )
}

export default Filter
