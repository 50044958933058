import React from 'react'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import Sidebar from './components/Sidebar/Sidebar'
import styles from './step-one.module.scss'
const Index = () => {
    return (
        <div className={styles.container}>
            <Step
                step={1}
                title="target_audience.select_targeting_type"
                description="Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов, но это не совсем так."
                hide={true}
            />
            <Sidebar />
        </div>
    )
}

export default Index
