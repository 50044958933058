import React, { FC } from 'react'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import Button from '@/components/Button'
import cn from './nav-footer.module.scss'
import { ReactComponent as ArrowIcon } from '../../icons/short_left.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface IProps {
    prev: string
    next: string
}

const Index: FC<IProps> = ({ prev, next }) => {
    const { t } = useTranslation()
    return (
        <Flex align="center" content="space-between" className={cn['nav-footer']}>
            <Link to={prev}>
                <Flex align="center" style={{ cursor: 'pointer' }}>
                    <ArrowIcon className={cn['icon-prev']} />
                    <Text margin="0 0 0 8px" className={cn['text']}>
                        {t('target_audience.back')}
                    </Text>
                </Flex>
            </Link>
            <Link to={next}>
                <Button className={cn['btn']}>
                    {t('target_audience.next')}
                    <ArrowIcon className={cn['icon-next']} />
                </Button>
            </Link>
        </Flex>
    )
}

export default Index
