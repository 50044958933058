import React, { FC } from 'react'
import { Flex } from '@/_yc'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import cn from './step-three.module.scss'
import MultiSelect from '@/pages/TargetAudience/components/MultiSelect'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCountries, setSelectedGenders, setSelectedAges } from '@/_redux/_reducers/target_audience.reducer'

interface CountryOption {
    label: string
    value: string
}

const countries: CountryOption[] = [
    { label: 'Russian Federation', value: 'RU' },
    { label: 'Armenia', value: 'AM' },
    { label: 'USA', value: 'US' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Mexico', value: 'MX' },
]

const audienceOptions = [
    { label: 'Мужчины', value: 'men' },
    { label: 'Женщины', value: 'women' },
]

const ageOptions = [
    { label: '18-24 года', value: '18-24' },
    { label: '25-34 года', value: '25-34' },
    { label: '35-44 года', value: '35-44' },
    { label: '45-54 года', value: '45-54' },
    { label: '55-64 года', value: '55-64' },
    { label: '65 лет и старше', value: '65+' },
]

const Index: FC = () => {
    const dispatch = useDispatch()
    const baseOnData = useSelector((state: any) => state.targetAudience.step_1.basedOnData)
    const { t } = useTranslation()

    const handleCountryChange = (selectedCountries: { label: string; value: string }[]) => {
        dispatch(setSelectedCountries(selectedCountries))
    }

    const handleGenderChange = (selectedGenders: { label: string; value: string }[]) => {
        dispatch(setSelectedGenders(selectedGenders))
    }

    const handleAgeChange = (selectedAges: { label: string; value: string }[]) => {
        dispatch(setSelectedAges(selectedAges))
    }

    return (
        <Flex column className={cn['main']}>
            <Flex className={cn['container']}>
                <Step
                    step={3}
                    title={t('target_audience.demographicParameters')}
                    description={
                        'Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов, но это не совсем так.'
                    }
                    targetingType={baseOnData}
                />

                <Flex column className={cn['sidebar']}>
                    <Flex column>
                        <Text fSize={12} color="gray-new-4">
                            {t('target_audience.location')}
                        </Text>
                        <MultiSelect
                            onChange={handleCountryChange}
                            options={countries}
                            placeholder={t('target_audience.selectCountry')}
                        />
                    </Flex>
                    <Flex column>
                        <Text fSize={12} color="gray-new-4">
                            {t('target_audience.gender')}
                        </Text>
                        <MultiSelect
                            onChange={handleGenderChange}
                            options={audienceOptions}
                            placeholder={t('target_audience.selectGender')}
                        />
                    </Flex>
                    <Flex column>
                        <Text fSize={12} color="gray-new-4">
                            {t('target_audience.age')}
                        </Text>
                        <MultiSelect
                            onChange={handleAgeChange}
                            options={ageOptions}
                            placeholder={t('target_audience.selectAge')}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <NavFooter prev={'/target-audience/step-two'} next={'/target-audience/step-four'} />
        </Flex>
    )
}

export default Index
