import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AllDataReports from '@/pages/TargetAudience/Views/AllDataReports'
import Drafts from '@/pages/TargetAudience/Views/Drafts'
import Reports from '@/pages/TargetAudience/Views/Reports'
import ListLayout from '@/pages/TargetAudience/components/Layouts/ListLayout'
import CreateReportLayout from '@/pages/TargetAudience/components/Layouts/CreateReportLayout'
import CreateReportStepOne from '@/pages/TargetAudience/Views/CreateReportStepOne'
import CreateReportStepTwo from '@/pages/TargetAudience/Views/CreateReportStepTwo'
import CreateReportStepThree from '@/pages/TargetAudience/Views/CreateReportStepThree'
import CreateReportStepFour from '@/pages/TargetAudience/Views/CreateReportStepFour'
import CreateReportStepFive from '@/pages/TargetAudience/Views/CreateReportStepFive'
import CreateReportDetailsLayout from '@/pages/TargetAudience/components/Layouts/CreateReportDetailsLayout'

const TargetAudienceRoutes = () => {
    return (
        <Switch>
            <Route path="/target-audience/all">
                <ListLayout>
                    <AllDataReports />
                </ListLayout>
            </Route>
            <Route path="/target-audience/reports">
                <ListLayout>
                    <Reports />
                </ListLayout>
            </Route>
            <Route path="/target-audience/drafts">
                <ListLayout>
                    <Drafts />
                </ListLayout>
            </Route>

            <Route path="/target-audience/step-one">
                <CreateReportLayout>
                    <CreateReportStepOne />
                </CreateReportLayout>
            </Route>
            <Route path="/target-audience/step-two">
                <CreateReportLayout>
                    <CreateReportStepTwo />
                </CreateReportLayout>
            </Route>
            <Route path="/target-audience/step-three">
                <CreateReportLayout>
                    <CreateReportStepThree />
                </CreateReportLayout>
            </Route>
            <Route path="/target-audience/step-four">
                <CreateReportLayout>
                    <CreateReportStepFour />
                </CreateReportLayout>
            </Route>
            <Route path="/target-audience/step-five">
                <CreateReportDetailsLayout>
                    <CreateReportStepFive />
                </CreateReportDetailsLayout>
            </Route>
        </Switch>
    )
}

export default TargetAudienceRoutes
