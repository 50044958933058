import React from 'react'
import cn from './step.module.scss'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { useTranslation } from 'react-i18next'
import Targeting from '@/pages/TargetAudience/Views/components/Targeting'

interface StepProps {
    step: number
    title: string
    description: string
    targetingType?: string
    hide?: boolean
}

const Step: React.FC<StepProps> = ({ step, title, description, targetingType, hide }) => {
    const { t } = useTranslation()
    return (
        <div className={cn['stepContainer']}>
            <Flex style={{ width: '100%' }} content={'space-between'} align={'start'}>
                {!hide && <Targeting targetingType={targetingType as 'Yoloco' | 'Beeline' | 'Users'} />}
                <Flex>
                    <span className={cn['stepNumber']}>
                        {t('target_audience.step')} {step}
                    </span>
                </Flex>
            </Flex>
            <Flex content={'center'} align={'center'} className={cn['wrapper']}>
                <Flex className={cn['content']} column>
                    <Flex className={cn['avatar']} />
                    <Text component="h1" fSize={'20px'} color={'gray-new-4'} margin="0 0 10px 0">
                        {t(title)}
                    </Text>
                    <Text fSize={'12px'} color={'gray-new-4'}>
                        {t(description)}
                    </Text>
                </Flex>
            </Flex>
        </div>
    )
}

export default Step
