import React, { FC } from 'react'
import { Flex } from '@/_yc'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import Step from '@/pages/TargetAudience/Views/components/Step/Step'
import cn from './step-four.module.scss'
import MultiSelect from '@/pages/TargetAudience/components/MultiSelect'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedInterests } from '@/_redux/_reducers/target_audience.reducer'

const audienceInterestsOptions = [
    { value: 'technology', label: 'Технологии' },
    { value: 'sports', label: 'Спорт' },
    { value: 'music', label: 'Музыка' },
    { value: 'travel', label: 'Путешествия' },
    { value: 'fitness', label: 'Фитнес' },
    { value: 'movies', label: 'Кино' },
    { value: 'gaming', label: 'Видеоигры' },
    { value: 'fashion', label: 'Мода' },
    { value: 'cooking', label: 'Кулинария' },
    { value: 'literature', label: 'Литература' },
    { value: 'photography', label: 'Фотография' },
    { value: 'art', label: 'Искусство' },
    { value: 'education', label: 'Образование' },
    { value: 'finance', label: 'Финансы' },
    { value: 'science', label: 'Наука' },
    { value: 'health', label: 'Здоровье' },
    { value: 'politics', label: 'Политика' },
    { value: 'automotive', label: 'Автомобили' },
    { value: 'gardening', label: 'Садоводство' },
    { value: 'pets', label: 'Домашние животные' },
]

const Index: FC = () => {
    const dispatch = useDispatch()
    const baseOnData = useSelector((state: any) => state.targetAudience.step_1.basedOnData)
    const { t } = useTranslation()

    const handleInterestsChange = (selectedInterests: { label: string; value: string }[]) => {
        dispatch(setSelectedInterests(selectedInterests))
    }

    return (
        <Flex column className={cn['main']}>
            <Flex className={cn['container']}>
                <Step
                    step={4}
                    title={t('target_audience.targeting_type')}
                    description="target_audience.nicheCampaigns"
                    targetingType={baseOnData}
                />

                <Flex column className={cn['sidebar']}>
                    <Flex column>
                        <Text fSize={12} color="gray-new-4">
                            {t('target_audience.interests')}
                        </Text>
                        <MultiSelect
                            onChange={handleInterestsChange}
                            options={audienceInterestsOptions}
                            placeholder={t('target_audience.selectInterests')}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <NavFooter prev={'/target-audience/step-three'} next={'/target-audience/step-five'} />
        </Flex>
    )
}

export default Index
