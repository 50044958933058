import React, { FC } from 'react'
import cn from './step-two.module.scss'
import { Flex } from '@/_yc'
import Step from '../components/Step/Step'
import { Input } from '@/components/Input/Input'
import Text from '@/components/Text'
import CustomSelect from '../../components/CustomSelect'
import Textarea from '@/pages/TargetAudience/components/TextArea'
import NavFooter from '@/pages/TargetAudience/components/NavFooter'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    setReportName,
    setProductCategory,
    setProductDescription,
    setSaitLink,
} from '@/_redux/_reducers/target_audience.reducer'

const options = [
    { label: 'Свой вариант', value: '8' },
    { label: 'Вариант 1', value: '1' },
    { label: 'Вариант 2', value: '2' },
    { label: 'Вариант 3', value: '3' },
    { label: 'Вариант 4', value: '4' },
]

const Index: FC = () => {
    const dispatch = useDispatch()
    const step2State = useSelector((state: any) => state.targetAudience.step_2)
    const baseOnData = useSelector((state: any) => state.targetAudience.step_1.basedOnData)
    const { t } = useTranslation()

    const handleSelect = (value: string) => {
        dispatch(setProductCategory(value))
    }
    console.log(baseOnData)
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        if (name === 'reportName') {
            dispatch(setReportName(value))
        } else if (name === 'saitLink') {
            dispatch(setSaitLink(value))
        }
    }

    const handleTextareaChange = (value: string) => {
        dispatch(setProductDescription(value))
    }

    return (
        <Flex column className={cn['wrapper']}>
            <Flex className={cn['container']}>
                <Step
                    step={2}
                    title="target_audience.report_creation"
                    description="Многие думают, что Lorem Ipsum — взятый с потолка псевдо-латинский набор слов, но это не совсем так."
                    targetingType={baseOnData}
                />
                <Flex column className={cn['block-form']}>
                    <Input
                        className={cn['input']}
                        label={t('target_audience.report_title')}
                        name="reportName"
                        value={step2State.reportName}
                        onChange={handleInputChange}
                        placeholder={t('target_audience.report_title')}
                    />

                    <Flex column className={cn['select-wrapper']}>
                        <Text fSize={12}>{t('target_audience.product_category')}</Text>
                        <CustomSelect
                            options={options}
                            onSelect={handleSelect}
                            placeholder={t('target_audience.select_product_category')}
                        />
                    </Flex>

                    {step2State.productCategory === '8' && (
                        <Textarea
                            onChange={handleTextareaChange}
                            label={t('target_audience.enter_product_description')}
                            maxLength={100}
                            placeholder={t('target_audience.enter_product_description')}
                        />
                    )}

                    <Input
                        className={cn['input']}
                        label={t('target_audience.website_link')}
                        placeholder={t('target_audience.enter_website')}
                        name="saitLink"
                        value={step2State.saitLink}
                        onChange={handleInputChange}
                    />
                </Flex>
            </Flex>
            <NavFooter prev="/target-audience/step-one" next="/target-audience/step-three" />
        </Flex>
    )
}

export default Index
