import React from 'react'
import styles from '@/pages/TargetAudience/Views/components/list/ItemList/item-list.module.scss'
import { Checkbox, Flex } from '@/_yc'
import { Data } from '@/pages/TargetAudience/Views/AllDataReports/data'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as Yoloco } from '../../../../icons/yoloco.svg'
import { ReactComponent as Beeline } from '../../../../icons/biline.svg'
import { ReactComponent as User } from '../../../../icons/users.svg'
import { ReactComponent as Draft } from '../../../../icons/draft.svg'

interface TableRowProps {
    item: Data
}
const icons = {
    Yoloco: <Yoloco />,
    Beeline: <Beeline />,
    User: <User />,
    Draft: <Draft />,
}

const Index: React.FC<TableRowProps> = ({ item }) => {
    const { t } = useTranslation()
    return (
        <Flex className={styles.row}>
            <div>
                <Checkbox value={false} className={styles['checkbox']} />
            </div>
            <Flex>
                <Flex margin="0 10px 0 0">{icons[item.type]}</Flex>
                <Flex column className={styles['name-wrapper']}>
                    <Text className={styles['name']}>{item.name}</Text>
                    {item.type === 'Draft' && <Text className={styles['sub-name']}>Ченовик</Text>}
                </Flex>
            </Flex>
            <Flex>
                <Text fSize={'12px'} color={'gray-new-1'}>
                    {item.type}
                </Text>
            </Flex>
            <Flex>
                <Text fSize={'12px'} color={'gray-new-1'}>
                    {item.date}
                </Text>
            </Flex>
            <div className={styles.status}>
                <Flex className={item.status === 'Готов' ? styles['status-completed'] : styles['status-progress']}>
                    <Text fSize={'10px'} color={'gray-new-4'}>
                        {item.status}
                    </Text>
                </Flex>
            </div>
            <Flex content="end">
                <Link to="/target-audience/step-one">
                    <button disabled={item.status === 'В процессе'} className={styles.actionButton}>
                        {t('target_audience.open')}
                    </button>
                </Link>
            </Flex>
        </Flex>
    )
}

export default Index
