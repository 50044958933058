import React from 'react'
import cn from './step-five.module.scss'
import Filter from './components/Filter'
import { ageOptions, cityOptions, countryOptions, genderOptions, interestsOptions } from './const'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import SelectedFilters from '../components/SelectedFilters'
import { updateFilters } from '@/pages/TargetAudience/utils'
import Button from '@/components/Button'
import { ReactComponent as ArrowIcon } from '../../icons/short_left.svg'
import { useSelector } from 'react-redux'
import Targeting from '@/pages/TargetAudience/Views/components/Targeting'

const initialOptions = [
    { label: 'target_audience.country', value: 'country' },
    { label: 'target_audience.city', value: 'city' },
    { label: 'target_audience.gender', value: 'gender' },
    { label: 'target_audience.age', value: 'age' },
    { label: 'target_audience.interests', value: 'interests' },
]

const selectTypesOptions = {
    country: countryOptions,
    city: cityOptions,
    gender: genderOptions,
    age: ageOptions,
    interests: interestsOptions,
}

const Index = () => {
    const { t } = useTranslation()
    const [addFilter, setAddFilter] = React.useState<Record<string, { label: string; values: any[] }>[]>([])
    const [shrinkFilter, setShrinkFilter] = React.useState<Record<string, { label: string; values: any[] }>[]>([])

    const [addDraftFilter, setAddDraftFilter] = React.useState<Record<string, { label: string; values: any[] }>>({})
    const [shrinkDraftFilter, setShrinkDraftFilter] = React.useState<Record<string, { label: string; values: any[] }>>(
        {}
    )
    const baseOnData = useSelector((state: any) => state.targetAudience.step_1.basedOnData)

    const handleDraftFiltersSubmit =
        (type: 'shrink' | 'add') => (filters: Record<string, { label: string; values: any[] }>) => {
            if (type === 'shrink') {
                setShrinkDraftFilter(filters)
            } else {
                setAddDraftFilter(filters)
            }
        }

    const handleAddFiltersSubmit = (filters: Record<string, { label: string; values: any[] }>) => {
        setAddFilter((prev) => [...prev, filters])
    }
    const handleShrinkFiltersSubmit = (filters: Record<string, { label: string; values: any[] }>) => {
        setShrinkFilter((prev) => [...prev, filters])
    }

    const handleAddDelete = (filterIndex: number, filterKey: string, valueKey: string) => {
        setAddFilter((prevFilters) => updateFilters(prevFilters, filterIndex, filterKey, valueKey))
    }

    const handleShrinkDelete = (filterIndex: number, filterKey: string, valueKey: string) => {
        setShrinkFilter((prevFilters) => updateFilters(prevFilters, filterIndex, filterKey, valueKey))
    }

    const removeFilter = (type: 'shrink' | 'add') => (filterIndex: number) => {
        if (type === 'shrink') {
            setShrinkFilter((prevFilters) => prevFilters.filter((_, index) => index !== filterIndex))
        } else {
            setAddFilter((prevFilters) => prevFilters.filter((_, index) => index !== filterIndex))
        }
    }

    const saveDraft = () => {
        console.log(shrinkDraftFilter, addDraftFilter)
    }

    return (
        <div>
            <div className={cn['main']}>
                <Flex column className={cn['step']}>
                    <Flex style={{ width: '100%' }} content={'space-between'} align={'start'}>
                        <Targeting targetingType={baseOnData} />
                        <Text className={cn['step-chip']} fSize={'10px'} color={'white'}>
                            {t('target_audience.step')} 5
                        </Text>
                    </Flex>
                    <Flex column className={cn['content']}>
                        <Flex className={cn['avatar']} />
                        <Text component="h2" fSize={'20px'} color={'gray-new-4'} margin="0 0 10px 0">
                            {t('target_audience.additional_filters')}
                        </Text>
                        <Text fSize={'12px'} color={'gray-new-4'}>
                            {t('target_audience.step_description')}
                        </Text>
                    </Flex>
                </Flex>
                <Text fSize={'12px'} color={'gray-new-4'}>
                    {t('target_audience.found_influencers', { count: 120980 })}
                </Text>
                <Flex style={{ gap: '20px' }}>
                    <Flex style={{ gap: '20px' }} column className={cn['block']}>
                        <Filter
                            onSubmit={handleAddFiltersSubmit}
                            onSubmitDraft={handleDraftFiltersSubmit('add')}
                            initialOptions={initialOptions}
                            selectTypesOptions={selectTypesOptions}
                            type={'add'}
                            title={t('target_audience.expand_search')}
                            description={t('target_audience.expand_search_description')}
                        />
                        {addFilter.map((item, index) => (
                            <SelectedFilters
                                key={index}
                                filter={item}
                                onDelete={handleAddDelete}
                                index={index}
                                removeFilter={removeFilter('add')}
                                type="add"
                            />
                        ))}
                    </Flex>
                    <Flex style={{ gap: '20px' }} column className={cn['block']}>
                        <Filter
                            onSubmit={handleShrinkFiltersSubmit}
                            onSubmitDraft={handleDraftFiltersSubmit('shrink')}
                            initialOptions={initialOptions}
                            selectTypesOptions={selectTypesOptions}
                            type={'shrink'}
                            title={t('target_audience.narrow_search')}
                            description={t('target_audience.narrow_search_description')}
                        />
                        {shrinkFilter.map((item, index) => (
                            <SelectedFilters
                                key={index}
                                filter={item}
                                onDelete={handleShrinkDelete}
                                index={index}
                                removeFilter={removeFilter('shrink')}
                                type="shrink"
                            />
                        ))}
                    </Flex>
                </Flex>
            </div>
            <Flex content="end" className={cn['footer']}>
                <Flex style={{ gap: '16px' }}>
                    {!shrinkFilter.length && !addFilter.length && (
                        <Button onClick={saveDraft}>{t('target_audience.save_draft')}</Button>
                    )}
                    {(shrinkFilter.length > 0 || addFilter.length > 0) && <Button>{t('target_audience.save')}</Button>}
                    <Button className={cn['btn-next']}>
                        {t('target_audience.start_analysis')}
                        <ArrowIcon className={cn['icon-next']} />
                    </Button>
                </Flex>
            </Flex>
        </div>
    )
}

export default Index
