import React from 'react'
import Select from 'react-select'

interface Option {
    label: string
    value: string
}

interface SelectProps {
    options: Option[]
    placeholder?: string
    onSelect: (value: string) => void
}

const customStyles = {
    control: (base: any) => ({
        ...base,
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#323841',
        boxShadow: 'none',
        borderColor: '#AEBACC',
        '&:hover': {
            borderColor: '#aaa',
        },
    }),
    menu: (base: any) => ({
        ...base,
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#323841',
    }),
    option: (base: any) => ({
        ...base,
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#323841',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    }),
    indicatorSeparator: () => null,
}

const Index: React.FC<SelectProps> = ({ options, placeholder = 'Выберите вариант', onSelect }) => {
    const handleChange = (selectedOption: Option | null) => {
        if (selectedOption) {
            onSelect(selectedOption.value)
        }
    }

    return <Select styles={customStyles} options={options} placeholder={placeholder} onChange={handleChange} />
}

export default Index
