import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as MinusIcon } from '../../../../icons/minus.svg'
import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg'
import cn from './button-menu.module.scss'
import { useTranslation } from 'react-i18next'

interface Option {
    label: string
    value: string
}

interface MenuProps {
    options: Option[]
    onSelect: (selectedOption: Option) => void
    type: 'add' | 'shrink'
}

const DynamicMenu: React.FC<MenuProps> = ({ options, onSelect, type }) => {
    const [open, setOpen] = useState(false)
    const menuRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const handleClick = () => {
        if (options.length > 0) {
            setOpen(!open)
        }
    }

    const handleSelect = (option: Option) => {
        onSelect(option) // Передаем выбранный элемент наверх
        setOpen(false) // Закрываем меню после выбора
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setOpen(false) // Закрываем меню, если клик был вне его области
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className={cn['root']} ref={menuRef}>
            <button onClick={handleClick} className={cn['btn']}>
                {type === 'add' ? <PlusIcon /> : <MinusIcon />}
            </button>

            {open && options.length > 0 && (
                <ul className={`${cn['list-menu']} ${open ? cn['show'] : cn['hide']}`}>
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className={cn['list-item']}
                            onClick={() => handleSelect(option)} // Выбор элемента
                        >
                            {t(option.label)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default DynamicMenu
