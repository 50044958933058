import React, { FC } from 'react'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import { ReactComponent as YolocoIcon } from '../../../icons/yoloco.svg'
import { ReactComponent as BilineIcon } from '../../../icons/biline.svg'
import { ReactComponent as UsersIcon } from '../../../icons/users.svg'
import { useTranslation } from 'react-i18next'

const textType = {
    Yoloco: 'target_audience.yoloco_data',
    Beeline: 'target_audience.beeline_audience_data',
    Users: 'target_audience.user_data',
}

const iconType = {
    Yoloco: <YolocoIcon style={{ marginRight: '10px' }} />,
    Beeline: <BilineIcon style={{ marginRight: '10px' }} />,
    Users: <UsersIcon style={{ marginRight: '10px' }} />,
}

const Index: FC<{ targetingType: 'Beeline' | 'Users' | 'Yoloco' }> = ({ targetingType }) => {
    const { t } = useTranslation()
    if (!targetingType) {
        targetingType = 'Yoloco'
    }
    return (
        <Flex align="center">
            {iconType[targetingType]}
            <Text fSize={12} color="gray-new-1">
                {t(textType[targetingType])}
            </Text>
        </Flex>
    )
}

export default Index
