import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Flex } from '@/_yc'
import cn from './tabs.module.scss'
import { useTranslation } from 'react-i18next'

const tabs = [
    { path: '/target-audience/all', label: 'target_audience.all_data' },
    { path: '/target-audience/reports', label: 'target_audience.reports' },
    { path: '/target-audience/drafts', label: 'target_audience.drafts' },
]

const Tabs = () => {
    const pathname = useLocation()
    const { t } = useTranslation()
    return (
        <nav className={cn['nav']}>
            {tabs.map(({ path, label }) => (
                <Flex
                    component={NavLink}
                    className={`${cn.link} ${pathname.pathname === path ? cn.active : ''}`}
                    key={path}
                    to={path}
                    align="center"
                    style={{ marginRight: '16px' }}
                >
                    <span>{t(label)}</span>
                </Flex>
            ))}
        </nav>
    )
}

export default Tabs
