import React, { FC } from 'react'
import styles from './list.module.scss'
import { Data } from '@/pages/TargetAudience/Views/AllDataReports/data'
import HeaderList from '@/pages/TargetAudience/Views/components/list/HeaderList'
import ItemList from '@/pages/TargetAudience/Views/components/list/ItemList'
import EmptyState from '@/pages/TargetAudience/Views/components/EmptyState/EmptyState'

interface TableProps<T> {
    headers: string[]
    data: Data[]
}

const Index: FC<TableProps<Data>> = <T,>({ headers, data }: TableProps<T>) => {
    return (
        <div className={styles['list']}>
            <HeaderList headers={headers} />

            <div className={styles.body}>
                {data.map((item, index) => (
                    <ItemList key={index} item={item} />
                ))}
            </div>
            {!data.length && <EmptyState />}
        </div>
    )
}

export default Index
