import React, { useState, useRef, useEffect } from 'react'
import styles from './multi-select.module.scss'
import { ReactComponent as ChevronDown } from '../../icons/chevron_down.svg'
import { ReactComponent as DoneIcon } from '../../icons/done.svg'

interface Option {
    label: string
    value: string
}

interface MultiSelectProps {
    options: Option[]
    placeholder: string
    onChange: (selected: Option[]) => void
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, placeholder, onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        onChange(selectedOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions])

    const handleRemoveOption = (option: Option) => {
        setSelectedOptions(selectedOptions.filter((o) => o.value !== option.value))
    }

    const handleSelectOption = (option: Option) => {
        if (!selectedOptions.includes(option)) {
            setSelectedOptions([...selectedOptions, option])
        } else {
            handleRemoveOption(option)
        }
    }
    const handleRemoveAllOptions = (event: React.MouseEvent) => {
        event.stopPropagation()
        setSelectedOptions([])
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    return (
        <div className={styles['multi-select-wrapper']} ref={selectRef}>
            <div className={styles.multiSelect}>
                <div
                    className={`${styles.selectBox} ${selectedOptions.length > 0 ? styles.selected : ''}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className={styles.selectedOptions}>
                        {selectedOptions.length > 0 ? (
                            <span className={styles.chip} onClick={handleRemoveAllOptions}>
                                Selected: {selectedOptions.length} ✕
                            </span>
                        ) : (
                            <span className={styles['placeholder']}>{placeholder}</span>
                        )}
                    </div>
                    <div className={`${styles.arrow} ${isOpen ? styles.open : ''}`}>
                        <ChevronDown />
                    </div>
                </div>
                <div className={`${styles.optionsList} ${isOpen ? styles.show : styles.hide}`}>
                    {options.map((option) => {
                        const selected = selectedOptions.includes(option)
                        return (
                            <div
                                key={option.value}
                                className={`${styles.option} ${selected ? styles.selected : ''}`}
                                onClick={() => handleSelectOption(option)}
                            >
                                {option.label}
                                {selected && <DoneIcon />}
                            </div>
                        )
                    })}
                </div>
            </div>
            {selectedOptions.length > 0 && (
                <div className={styles['chip-container']}>
                    {selectedOptions.map((option) => (
                        <span key={option.value} className={styles.tag}>
                            {option.label} <span onClick={() => handleRemoveOption(option)}>✕</span>
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MultiSelect
