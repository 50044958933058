import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    step_1: {
        basedOnData: '',
    },
    step_2: {
        reportName: '',
        productCategory: '',
        productDescription: '',
        saitLink: '',
    },
    step_3: {
        selectedCountries: [],
        selectedGenders: [],
        selectedAges: [],
    },
    step_4: {
        selectedInterests: [], // Новое состояние для интересов
    },
};

const targetAudienceSlice = createSlice({
    name: 'targetAudience',
    initialState,
    reducers: {
        setBasedOnData: (state, action) => {
            state.step_1.basedOnData = action.payload;
        },
        resetStep1: (state) => {
            state.step_1.basedOnData = '';
        },
        setReportName: (state, action) => {
            state.step_2.reportName = action.payload;
        },
        setProductCategory: (state, action) => {
            state.step_2.productCategory = action.payload;
        },
        setProductDescription: (state, action) => {
            state.step_2.productDescription = action.payload;
        },
        setSaitLink: (state, action) => {
            state.step_2.saitLink = action.payload;
        },
        resetStep2: (state) => {
            state.step_2 = initialState.step_2;
        },
        setSelectedCountries: (state, action) => {
            state.step_3.selectedCountries = action.payload;
        },
        setSelectedGenders: (state, action) => {
            state.step_3.selectedGenders = action.payload;
        },
        setSelectedAges: (state, action) => {
            state.step_3.selectedAges = action.payload;
        },
        resetStep3: (state) => {
            state.step_3 = initialState.step_3;
        },
        setSelectedInterests: (state, action) => { // Новый редюсер для интересов
            state.step_4.selectedInterests = action.payload;
        },
        resetStep4: (state) => { // Сброс данных шага 4
            state.step_4 = initialState.step_4;
        },
    }
});

export const {
    setBasedOnData,
    resetStep1,
    setReportName,
    setProductCategory,
    setProductDescription,
    setSaitLink,
    resetStep2,
    setSelectedCountries,
    setSelectedGenders,
    setSelectedAges,
    resetStep3,
    setSelectedInterests, // Экспорт нового редюсера
    resetStep4, // Экспорт сброса шага 4
} = targetAudienceSlice.actions;

export default targetAudienceSlice.reducer;
