type Filter = Record<string, { label: string; values: { value: string }[] }>

export const updateFilters = (
    filters: Filter[],
    filterIndex: number,
    filterKey: string,
    valueKey: string
): Filter[] => {
    return filters
        .map((filter, index) => {
            if (index === filterIndex && filter[filterKey]) {
                const updatedValues = filter[filterKey].values.filter(
                    (item: { value: string }) => item.value !== valueKey
                )

                if (updatedValues.length === 0) {
                    const { [filterKey]: _, ...remainingFilters } = filter
                    return Object.keys(remainingFilters).length > 0 ? remainingFilters : null
                }

                return {
                    ...filter,
                    [filterKey]: {
                        ...filter[filterKey],
                        values: updatedValues,
                    },
                }
            }
            return filter
        })
        .filter((filter): filter is Filter => filter !== null)
}
