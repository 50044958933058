import { Button, Flex } from '@/_yc'
import Header from '../Header'
import Tabs from '../../Views/components/Tabs'
import React from 'react'
import cn from './layout.module.scss'

import { useTranslation } from 'react-i18next'

const ListLayout: React.FC = ({ children }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Flex content={'space-between'}>
                <Header />
                <Button size="medium" className={cn['create-btn']}>
                    + {t('target_audience.create_analysis')}
                </Button>
            </Flex>
            <Tabs />
            <div>{children}</div>
        </div>
    )
}
export default ListLayout
