import React, { FC } from 'react'
import { Button, Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import cn from './empty-state.module.scss'
import Icons from '@/components/Icons'

const EmptyState: FC = () => {
    const { t } = useTranslation()
    return (
        <Flex content="center" column align="center" className={cn.empty} margin="55px 0 55px 0">
            <Flex className={cn.wrap} margin="0 0 24px 0">
                <Icons name={'icon-audience'} className={cn.icon} style={{}} />
            </Flex>
            <Flex margin="0 0 8px 0">
                <Text fSize={20} semibold color={'gray-new-1'}>
                    {t('target_audience.create_first_analysis')}
                </Text>
            </Flex>
            <Flex margin="0 0 24px 0">
                <Text fSize={14} semibold color={'gray-new-3'}>
                    {t('target_audience.description')}
                </Text>
            </Flex>
            <Flex>
                <Button className={cn['btn']} color="blue">
                    + {t('target_audience.create_analysis')}
                </Button>
            </Flex>
        </Flex>
    )
}

export default EmptyState
