export const countryOptions = [
    { label: 'USA', value: 'usa' },
    { label: 'Российская Федерация', value: 'russia' },
    { label: 'Армения', value: 'armenia' },
    { label: 'Грузия', value: 'georgia' },
    { label: 'Германия', value: 'germany' },
    { label: 'Франция', value: 'france' },
    { label: 'Япония', value: 'japan' },
    { label: 'Китай', value: 'china' },
    { label: 'Канада', value: 'canada' },
    { label: 'Австралия', value: 'australia' },
]
export const cityOptions = [
    { label: 'Нью-Йорк', value: 'new_york' },
    { label: 'Москва', value: 'moscow' },
    { label: 'Ереван', value: 'yerevan' },
    { label: 'Тбилиси', value: 'tbilisi' },
    { label: 'Берлин', value: 'berlin' },
    { label: 'Париж', value: 'paris' },
    { label: 'Токио', value: 'tokyo' },
    { label: 'Пекин', value: 'beijing' },
    { label: 'Торонто', value: 'toronto' },
    { label: 'Сидней', value: 'sydney' },
]
export const genderOptions = [
    { label: 'Мужской', value: 'male' },
    { label: 'Женский', value: 'female' },
    { label: 'Другой', value: 'other' },
]
export const ageOptions = [
    { label: '18-24 года', value: '18-24' },
    { label: '25-34 года', value: '25-34' },
    { label: '35-44 года', value: '35-44' },
    { label: '45-54 года', value: '45-54' },
    { label: '55-64 года', value: '55-64' },
    { label: '65 лет и старше', value: '65+' },
]
export const interestsOptions = [
    { label: 'Технологии', value: 'technology' },
    { label: 'Спорт', value: 'sports' },
    { label: 'Мода', value: 'fashion' },
    { label: 'Путешествия', value: 'travel' },
    { label: 'Еда', value: 'food' },
    { label: 'Кино', value: 'movies' },
    { label: 'Музыка', value: 'music' },
    { label: 'Образование', value: 'education' },
    { label: 'Автомобили', value: 'cars' },
    { label: 'Финансы', value: 'finance' },
]
