import React, { useState } from 'react'
import styles from './textarea.module.scss'
import { Flex } from '@/_yc'

interface TextareaProps {
    label: string
    maxLength: number
    onChange: (value: string) => void
    placeholder?: string
}

const Textarea: React.FC<TextareaProps> = ({
    label,
    maxLength,
    onChange,
    placeholder = 'Введите описание продукта...',
}) => {
    const [value, setValue] = useState('')

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value
        setValue(newValue)
        onChange(newValue) // Передаем значение наверх
    }

    return (
        <div className={styles.textareaWrapper}>
            <Flex content="space-between">
                <label className={styles.label}>{label}</label>
                <div className={styles.counter}>
                    {value.length} / {maxLength}
                </div>
            </Flex>
            <textarea
                className={styles.textarea}
                value={value}
                onChange={handleChange}
                maxLength={maxLength}
                placeholder={placeholder}
            />
        </div>
    )
}

export default Textarea
