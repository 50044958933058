export interface Data {
    name: string
    type: 'Draft' | 'Yoloco' | 'Beeline' | 'User'
    date: string
    status: string
}

export const tableData: Data[] = [
    {
        name: 'Новый анализ',
        type: 'Draft',
        date: '04.09.2024',
        status: 'Черновик',
    },
    {
        name: 'Отчет 1',
        type: 'Yoloco',
        date: '04.09.2024',
        status: 'Готов',
    },
    {
        name: 'Подробный отчет 2',
        type: 'Beeline',
        date: '04.09.2024',
        status: 'В процессе',
    },
    {
        name: 'Подробный отчет 3',
        type: 'Beeline',
        date: '05.09.2024',
        status: 'Готов',
    },
    {
        name: 'Подробный отчет 4',
        type: 'User',
        date: '06.09.2024',
        status: 'Готов',
    },
]

export const headers = [
    'target_audience.name',
    'target_audience.targeting_type',
    'target_audience.creation_date',
    'target_audience.status',
    '',
]
