import React, { useEffect } from 'react'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import cn from './target-audience.module.scss'
import TargetAudienceRoutes from '@/pages/TargetAudience/Routes'
import { useHistory, useLocation } from 'react-router-dom'

const TargetAudience = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/target-audience') {
            history.push('/target-audience/all')
        }
    }, [history, location.pathname])

    return (
        <PageLayout className={cn['root']} title={t('Target Audience')} color="common-background">
            <div className={cn['audience']}>
                <TargetAudienceRoutes />
            </div>
        </PageLayout>
    )
}

export default TargetAudience
